<template>
  <head>
    <title>iSHOOTRUN.IN.TH</title>
   <!-- <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" /> -->
  </head>
  <router-view ></router-view>
</template>

<script>



export default {
  name: 'App'
}
</script>

